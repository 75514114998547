import React, { useContext, useEffect } from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"

/* Import Global Context(s) */
import AudioContext from "~context/audio"

/* Import Local Components */
import Duration from "./components/duration"

/* Import Global Asset(s) */
import ExpandSvgSrc from "~assets/images/expand.svg"
import CloseSvgSrc from "~assets/images/close.svg"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Local Style(s) */
import "./persistent-audio-controller.scss"

const PersistentAudioController = ({ location }) => {
  const {
    expandLinkUrl,
    showController,
    setShowController,
    backgroundColor,
    episodeNumber,
    handleSeekMouseDown,
    handleSeekChange,
    handleSeekMouseUp,
    setIsPlaying,
    isLoading,
    isPlaying,
    played,
    playedSeconds,
    duration,
  } = useContext(AudioContext)

  useEffect(() => {
    if (isPlaying && !location.pathname.includes("/podcast/podcast")) {
      setShowController(true)
    }
  }, [isPlaying, location, setShowController])

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying)
  }

  const closeAudioController = () => {
    setIsPlaying(false)
    setShowController(false)
  }

  return (
    <AnimatePresence>
      {showController && (
        <motion.div
          className="persistent-audio-controller"
          style={{ backgroundColor }}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={elementTransitionAnimation.variants}
        >
          <div
            className={classnames("audio-player", { "is-loading": isLoading })}
          >
            {/* EXPAND */}
            <Link to={expandLinkUrl} className="expand">
              <img src={ExpandSvgSrc} alt="Expand Player" />
            </Link>
            {/* EPISODE */}
            <p className="episode mobile-only">Episode {episodeNumber}</p>
            {/* PLAY / PAUSE */}
            <div className="controls">
              {isPlaying ? (
                <button onClick={handlePlayPause}>Pause</button>
              ) : (
                <button onClick={handlePlayPause}>Play</button>
              )}
            </div>
            {/* TIMELINE */}
            <div className="timeline">
              <input
                type="range"
                min={0}
                max={0.999999}
                step="any"
                value={isLoading ? 0 : played}
                onMouseDown={handleSeekMouseDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekMouseUp}
              />
            </div>
            {/* TIMECODE */}
            <div className="timecode">
              <Duration
                duration={isLoading ? 0 : duration}
                progress={isLoading ? 0 : playedSeconds}
              />
            </div>
            {/* CLOSE BUTTON */}
            <button className="close" onClick={closeAudioController}>
              <img src={CloseSvgSrc} alt="Expand Player" />
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default PersistentAudioController
