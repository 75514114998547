import { useContext, useEffect } from "react"
import "focus-visible"

/* Import Global Context */
import IntroContext from "~context/intro"
import HeaderContext from "~context/header"

/* Import Local Style(s) */
import "./layout.scss"

const Layout = ({ children, location }) => {
  const [, setIsIntroDone] = useContext(IntroContext)
  const [,setIsHeaderReady] = useContext(HeaderContext)

  useEffect(() => {
    if (location.pathname !== "/") {
      setIsIntroDone(true)
      setIsHeaderReady(true)
    }
  }, [location, setIsIntroDone])

  return children
}

export default Layout
