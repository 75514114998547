const duration = 0.15

const elementTransitionAnimation = {
  variants: {
    initial: {
      opacity: 0,
      transition: { duration },
    },
    animate: {
      opacity: 1,
      transition: { duration },
    },
    exit: { opacity: 0, transition: { duration } },
  },
}

export default elementTransitionAnimation
