import React, { useState, useEffect } from "react"
import useFirebase from "~hooks/useFirebase"

/* Import Global Context(s) */
import FirebaseContext from "~context/firebase"

const FirebaseProvider = ({ children }) => {
  const [firebaseInstance, setFirebaseInstance] = useState()
  const firebase = useFirebase()

  useEffect(() => {
    if (!firebase) {
      return
    } else {
      setFirebaseInstance(firebase)
    }
  }, [firebase])

  return (
    <FirebaseContext.Provider value={firebaseInstance}>
      {children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseProvider
