import React, { useState, useRef } from "react"
import ReactPlayer from "react-player"

/* Import Global Context(s) */
import AudioContext from "~context/audio"

const AudioProvider = ({ children }) => {
  // react player
  const reactPlayerRef = useRef()
  const [url, setUrl] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isSeeking, setIsSeeking] = useState(false)
  const [duration, setDuration] = useState(0)
  // const [progress, setProgress] = useState(0)
  const [played, setPlayed] = useState(0)
  const [playedSeconds, setPlayedSeconds] = useState(0)
  // application
  const [showController, setShowController] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState("#EB4B0F")
  const [expandLinkUrl, setExpandLinkUrl] = useState()
  const [episodeNumber, setEpisodeNumber] = useState()

  const handlePlay = () => {
    setIsPlaying(true)
  }

  const handlePause = () => {
    setIsPlaying(false)
  }

  const handleProgress = progress => {
    if (!isSeeking) {
      setPlayed(progress.played)
      setPlayedSeconds(progress.playedSeconds)
    }
  }

  const handleDuration = duration => {
    setDuration(Math.round(duration))
  }

  const handleReady = () => {
    setIsLoading(false)
  }

  const handleSeekMouseDown = e => {
    setIsSeeking(true)
  }

  const handleSeekChange = event => {
    setPlayed(parseFloat(event.target.value))
  }

  const handleSeekMouseUp = event => {
    setIsSeeking(false)
    if (reactPlayerRef && reactPlayerRef.current) {
      reactPlayerRef.current.seekTo(parseFloat(event.target.value))
    }
  }

  const setReactPlayerUrl = newUrl => {
    setIsPlaying(false)
    setIsLoading(true)
    setPlayed(0)
    setPlayedSeconds(0)
    setUrl(newUrl)
  }

  return (
    <AudioContext.Provider
      value={{
        setIsPlaying,
        setReactPlayerUrl,
        setExpandLinkUrl,
        setShowController,
        setBackgroundColor,
        handleSeekMouseDown,
        handleSeekChange,
        handleSeekMouseUp,
        setEpisodeNumber,
        url,
        isLoading,
        isPlaying,
        duration,
        played,
        playedSeconds,
        expandLinkUrl,
        backgroundColor,
        showController,
        episodeNumber,
      }}
    >
      <ReactPlayer
        className="react-player-context"
        ref={reactPlayerRef}
        url={url}
        playing={isPlaying}
        onPlay={handlePlay}
        onPause={handlePause}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onReady={handleReady}
        width="0px"
        height="0px"
      />
      {children}
    </AudioContext.Provider>
  )
}

export default AudioProvider
