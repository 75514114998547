import React, { useState, useEffect, useContext } from "react"

/* Import Global Contexts */
import FirebaseContext from "~context/firebase"
import AuthContext from "~context/auth"

const AuthProvider = ({ children }) => {
  const firebase = useContext(FirebaseContext)

  const [firebaseUser, setFirebaseUser] = useState(null)

  useEffect(() => {
    if (firebase) {
      firebase.auth().onAuthStateChanged((user, event) => {
        if (user) {
          setFirebaseUser(user)
        } else {
          setFirebaseUser(null)
        }
      })
    }
  }, [firebase])

  return (
    <AuthContext.Provider value={firebaseUser}>{children}</AuthContext.Provider>
  )
}

export default AuthProvider
