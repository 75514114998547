import React, { useState } from "react"

import { CookiesProvider } from 'react-cookie';

import AccountContext from "~context/account"
import TokenContext from "~context/token"


const ProfileProvider = ({ children }) => {
  

  const [tokenString, setToken] = useState(false);
  const [accountInfo, setAccountInfo] = useState(false);

  return (
    <CookiesProvider>
      <TokenContext.Provider value={[tokenString, setToken]} >
        <AccountContext.Provider value={[accountInfo, setAccountInfo]}>
          {children}
        </AccountContext.Provider>
      </TokenContext.Provider>
    </CookiesProvider>
  )
}

export default ProfileProvider
