/* https://invertase.io/blog/firebase-with-gatsby */
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"

// Firebase web config
const firebaseConfig = {
  apiKey: "AIzaSyCOg9Dy_1jSZmX7616RqpMBflF1vKYiJhk",
  authDomain: "untitled-art-fairs.firebaseapp.com",
  projectId: "untitled-art-fairs",
  storageBucket: "untitled-art-fairs.appspot.com",
  messagingSenderId: "645765673601",
  appId: "1:645765673601:web:0fef6659d7f972ef8cd380",
}

let instance = null

export default function getFirebase() {
  if (typeof window !== "undefined") {
    if (instance) return instance
    instance = firebase.initializeApp(firebaseConfig)
    return instance
  }

  return null
}
