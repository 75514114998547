import React, { Fragment } from "react"
import { AnimatePresence } from "framer-motion"

/* Import Fonts */
import "~assets/fonts/untitled/untitled.scss"

/* Import Typography Style(s) */
import "~styles/typography.scss"

/* Import Style Reset(s) */
import "~styles/reset.scss"

/* Import Gatsby Reset(s)*/
import "~styles/gatsby.scss"

/* Import Global Style(s) */
import "~styles/global.scss"

/* Import Global Providers */
import AudioProvider from "~providers/audio"
import IntroProvider from "~providers/intro"
import FirebaseProvider from "~providers/firebase"
import AuthProvider from "~providers/auth"
import ProfileProvider from "~providers/profile"

/* Import Global Components */
import PersistentAudioController from "~components/persistentAudioController/persistentAudioController"

export const wrapPageElement = ({ element, props: { location } }) => (
  <Fragment>
    <PersistentAudioController location={location} />
    <AnimatePresence>{element}</AnimatePresence>
  </Fragment>
)

export const wrapRootElement = ({ element }) => (
  <IntroProvider>
    <ProfileProvider>
      <AudioProvider>
        <FirebaseProvider>
          <AuthProvider>{element}</AuthProvider>
        </FirebaseProvider>
      </AudioProvider>
    </ProfileProvider>
  </IntroProvider>
)

const removeTrailingSlash = string => {
  // if site has an end slash (like: www.example.com/),
  // then remove it and return the site without the end slash
  return string.replace(/\/$/, "") // Match a forward slash / at the end of the string ($)
}

// https://github.com/gatsbyjs/gatsby/issues/23842#issuecomment-665936677
const getLatestSavedScrollPosition = pathname => {
  let n = sessionStorage.length
  let i = 0

  const partialKey = `@@scroll|${pathname}|`

  let results = []

  while (++i < n) {
    const key = sessionStorage.key(i)

    if (key.includes(partialKey)) {
      results.push(key)
    }
  }

  if (results.length === 0) {
    return 0
  }

  results.sort()
  if (results.includes("@@scroll|/|initial")) {
    results.unshift(results.pop())
  }
  console.log("results", results)
  return sessionStorage.getItem(results[results.length - 1])
}

export const shouldUpdateScroll = args => {
  if (!args.prevRouterProps) {
    return true
  }
  console.log(args)
  const {
    pathname,
    prevRouterProps: {
      location: { pathname: previousPathname },
    },
  } = args

  // Check to See if Matches any set of Routes to Restore Scroll
  let restoreScroll = false
  // Check to see if returning from Podcast Item
  if (removeTrailingSlash(previousPathname).includes("/podcast/podcast")) {
    restoreScroll = true
  }

  // Check to see if returning from News Item
  if (removeTrailingSlash(previousPathname).includes("/news/post")) {
    restoreScroll = true
  }


  // Check to see if returning from Edit Item
  if (removeTrailingSlash(previousPathname).includes("/edits/post")) {
    restoreScroll = true
  }

  // Check to see if returning from Program Item
  if (removeTrailingSlash(previousPathname).includes("/program/event")) {
    restoreScroll = true
  }

  if (restoreScroll) {
    const savedYPosition = getLatestSavedScrollPosition(pathname)
    window.scrollTo(0, savedYPosition)
    return false
  } else {
    return true // default behavior
  }
}
