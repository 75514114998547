import React, { useState } from "react"

import IntroContext from "~context/intro"
import HeaderContext from "~context/header"

const IntroProvider = ({ children }) => {
  const [isIntroDone, setIsIntroDone] = useState(false)
  const [isHeaderReady, setIsHeaderReady] = useState(false)

  return (
    <HeaderContext.Provider value={[isHeaderReady, setIsHeaderReady]} >
      <IntroContext.Provider value={[isIntroDone, setIsIntroDone]}>
        {children}
      </IntroContext.Provider>
    </HeaderContext.Provider>
  )
}

export default IntroProvider
